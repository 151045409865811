import '@pch/surffers-components/style.css';

// import _ from 'lodash';
import $ from 'jquery';
window.$ = $;

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

